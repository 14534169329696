export const SERVICES = {
  MAIN: 'main',
  AUDIO: 'audio'
}

export const ENDPOINTS = {
  // Main
  USER: 'user',
  RECORDING_METADATA: 'recording',
  RECORDING_BUCKET: 'bucket'
}

export const TRANSCRIPTS_AGE = {
  // Main
  DEFAULT: 'default',
  KIDS: 'kids'
}

export const TRANSCRIPTS_TYPE = {
  // Main
  WW: 'WW',
  PHRASE: 'Phrase'
}

export const AUDIO_CONSTRAINTS: Record<string, boolean> = {
  audio: true,
  video: false
}

export const CONFIG_FILE_PATH = 'config/config.json'

export const WEB_SOCKET_KEYS = {
  // Main
  TYPE: 'type',
  VALUE: 'value',
  FILE_NAME: 'filename'
}

export const WEB_SOCKET_VALUES = {
  // Main
  SRMR: 'SRMR_SCORE',
  SPEECH: 'SPEECH'
}

export const EVENTS = {
  // Main
  STREAM_AVAILABLE: 'on-stream-available',
  WEB_SOCKET_CLOSE: 'on-web-socket-close',
  WEB_SOCKET_OPEN: 'on-web-socket-open'
}

export const UI_TEXT_TOAST_ERROR_UNAUTHORIZED = 'toastErrorTokenUnauthorised'

export const COLOR_VARIANT = {
  SUCCESS: 'success',
  DANGER: 'danger',
  WARNING: 'warning',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  INFO: 'info',
  LIGHT: 'light',
  DARK: 'dark'
}

export const TOAST_TITLES = {
  USERS: 'Users',
  RECORDINGS: 'Recordings'
}

export const TOAST_API_SUCCESS = {
}

export const TOAST_API_ERROR = {
  FEATURE_UNAUTHORISED: 'Your accessibility to this feature couldn\'t be established, please try again, refresh the page',
  CANNOT_SAVE_PART: 'Your recording could not be sent'
}

export const TOAST_API_WARNING = {
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const KEYS: Record<string, string> = {
  ACCENT: 'accent',
  AGE_GROUP: 'age_group',
  CITY: 'city',
  GENDER: 'gender',
  NAME: 'name',
  NATIVE_LANGUAGE: 'native_language',
  SECOND_LANGUAGE: 'second_language',
  USER_ID: 'user_id'
}

export const DB_FIELDS = {
  DEVICE_TYPE: 'session.device_type'
}

export const RECORDING_STATE = {
  IDLE: -1,
  BEGIN: 0,
  COUNTING_DOWN: 1,
  RECORDING: 2,
  PLAYBACK: 3,
  TIME_OUT: 4,
  ERROR: 5
}

export const NUM_ATTEMPT_RECONNECT = 5
export const enum SOCKET_STATUS {
  CONNECTING,
  OPENED,
  CLOSED,
  RECONNECTING,
  NO_ATTEMPT_LEFT,
  MANUAL_CLOSE
}

export const STATUS_CODE_UNAUTHORIZED = 401

export const SOCKET_TIMEOUT_RECONNECT = 4000

export const COUNTDOWN_INTERVAL = 1000 // Milliseconds
export const COUNTDOWN_TOTAL = 10 // Seconds

export const AUDIO_DOM_ID = 'playback'
export const AUDIO_DOM_PLAYBACK_ID = 'audioHtmlPlayback'

export const TIME_OUT_USER_NO_ACTION = 30000 // Sec
